/*
--------------------------------------------------
New Feature
--------------------------------------------------
*/
.newFeature {
    position: relative;
}
.newFeature::before {
    background-image: url(../images/new-badge-red.svg);
    background-size: contain;
    background-repeat: no-repeat;
    width: 45px;
    height: 45px;
    content: '';
    display: inline-block;
    vertical-align: middle;
    position: absolute;
    left: -65px;
    top: -20px;
}

.newFeatureExpired::before {
    width: 45px;
    height: 45px;
    content: '';
    display: inline-block;
    vertical-align: middle;
    position: absolute;
    left: -65px;
    top: -20px;
}
