/* Provide sufficient contrast against white background */
html, body {
  height: 100%;
  background-color: #f5f5f5;
}

a {
    color: #212a5c;
  }

.navbar {
    padding: 0px;
}

.navbar-nav li a {
  color: #212a5c;
}

.navbar-nav .nav-item a:hover{
  color: #fff;
  background-color: #212a5c;
}

.navbar-nav .nav-link.active{
  color: #fff;
  background-color: #212a5c;
}

.btn-primary {
  color: #fff;
  background-color: #212a5c;
  border-color: #212a5c;
  border-radius: 0%;
}

.btn-danger {
  border-radius: 0%;
}

.radio-margin {
  margin: 10px;
}

.dateRange-margin {
  margin: 10px;
}

.card
{
  border-radius: 0%;
}

.custom-grid {
  min-height: 90vh;
}

.custom-spinner
{
  position: fixed;
  left: 50%;
  top: 50%;
}